import { Component, OnInit } from '@angular/core';
import { EndpointService } from '../services/generic/endpoint.service';
import { AuthService } from '../services/auth/auth.service';
import { GenericService } from '../services/generic/generic.service';

@Component({
  selector: 'app-menu-generic',
  templateUrl: './menu-generic.component.html',
  styleUrls: ['./menu-generic.component.scss']
})
export class MenuGenericComponent implements OnInit {

  elements = [];
  expandAllMenusBool = true;

  constructor(
    private endpointService: EndpointService,
    private authService: AuthService,
    private genericService: GenericService
  ) { }

  ngOnInit(): void {
    if(this.genericService.menuStructure) {
      this.elements = this.genericService.cloneVariable(this.genericService.menuStructure);
    } else {
      this.endpointService.getMenuGeneric(this.authService.getIdCompany(), this.authService.userId, this.authService.profileId, this.authService.languageId).subscribe(data => {
        this.elements = data['response'];
        this.genericService.menuStructure = this.elements;
      })
    }
  }

  expandAllMenus(val) {
    this.expandAllMenusBool = val;
    this.elements.forEach(element => {
      element['show'] = this.expandAllMenusBool;
      if(element['children'] && !this.expandAllMenusBool) this.showRecursive(element['children']);
    });
  }

  showRecursive(children) {
    if(children) {
      children.forEach(element => {
        element['show'] = this.expandAllMenusBool;
        if(element['children']) this.showRecursive(element['children']);
      });
    }
  }

  closeAllMenus() {
    for(let i in this.genericService.paramControlVariables) {
      if(this.genericService.paramControlVariables[i]['params']) {
        this.genericService.paramControlVariables[i]['params'] = [];
      }
      if(this.genericService.paramControlVariables[i]['indexParam']) {
        this.genericService.paramControlVariables[i]['indexParam'] = 0;
      }
    }
    this.genericService.openSnackBar("Se han cerrado todos los menús de tipo FICHA", 2000);
    this.genericService.updateParamControl();
  }

}
